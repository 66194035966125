import { type Article } from '~/v1/_types/Article';
import { ArticleType, NewsSort } from '~/v1/_types/globalTypes';
import { Button } from '~/v1/components/button/button';
import { ButtonMode, ButtonType } from '~/v1/components/button/button.interface';
import { FieldCheckbox } from '~/v1/components/fields/checkbox/checkbox';
import { FieldRadio } from '~/v1/components/fields/radio/radio';
import { FieldRadioGroup } from '~/v1/components/fields/radio/radioGroup';
import { useFilterContext } from '~/v1/components/filter/filter.context';
import {
  FilterCategory,
  FilterSortOption,
  type SortId,
} from '~/v1/components/filter/filter.interface';
import { FilterSection } from '~/v1/components/filter/section/section';
import { FilterSidebar } from '~/v1/components/filter/sidebar/sidebar';
import { Column } from '~/v1/components/grid/column/column';
import { Grid } from '~/v1/components/grid/grid';
import { ListItem } from '~/v1/components/list/item/item';
import { mapListItem } from '~/v1/components/list/item/item.utils';
import { List } from '~/v1/components/list/list';

import styles from './filters.module.scss';

export const NEWS_TYPES = {
  GRANTEE: {
    id: ArticleType.GRANTEE,
    name: 'Grantee news',
  },
  NEWS: {
    id: ArticleType.MELLON_IN_THE_NEWS,
    name: 'Mellon news',
  },
  REPORT: {
    id: ArticleType.REPORT,
    name: 'Report',
  },
};

export const NewsFiltersSection: React.FC = () => {
  const { filterData, filterCount, onFilterLoadMore } = useFilterContext();

  return (
    <div className="section">
      <FilterSidebar title="Sort & Filter">
        <FilterSection title="Sort by">
          <FieldRadioGroup>
            {Object.keys(NewsSort).map(key => (
              <FieldRadio
                key={key}
                option={{
                  id: key as SortId,
                  name: FilterSortOption[key as NewsSort],
                }}
                isDefault={key === NewsSort.NEWEST}
              />
            ))}
          </FieldRadioGroup>
        </FilterSection>

        <FilterSection title="News type">
          {Object.values(NEWS_TYPES).map(newsType => (
            <FieldCheckbox
              key={newsType.id}
              filter={{ ...newsType, category: FilterCategory.TYPES }}
            />
          ))}
        </FilterSection>
      </FilterSidebar>

      <List>
        {(filterData as Article[]).map(data => (
          <ListItem key={data.id} {...mapListItem(data)} />
        ))}
      </List>

      {filterCount > filterData.length && (
        <Grid className={styles.loadMoreButton}>
          <Column offsetLeft={{ lg: 1 }} sm={5} md={7} lg={7}>
            <Button
              onClick={onFilterLoadMore}
              type={ButtonType.Primary}
              mode={ButtonMode.Light}
              text="Load more news"
            />
          </Column>
        </Grid>
      )}
    </div>
  );
};
