import { Column } from '../grid/column/column';
import { Grid } from '../grid/grid';
import { Link } from '../link/link';
import { type LinkData } from '../quickLinks/quickLinks';

export interface InlineLinkProps extends LinkData {
  isInline?: boolean;
  className?: string;
}

export function InlineLink({ label, link, isInline, className }: InlineLinkProps) {
  const content = (
    <Link className={className} href={link} withIcon>
      {label}
    </Link>
  );

  return isInline ? (
    content
  ) : (
    <Grid>
      <Column offsetLeft={{ lg: 1 }} sm={5} md={6} lg={5}>
        {content}
      </Column>
    </Grid>
  );
}
