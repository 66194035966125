import cx from 'classnames';

import { Email } from '~/v1/components/email/email';
import { Column } from '~/v1/components/grid/column/column';
import { Grid } from '~/v1/components/grid/grid';
import Markdown from '~/v1/components/markdown/markdown';

import styles from './contact.module.scss';

interface ContactModuleProps {
  className?: string;
  title: string;
  text: string;
  email: string;
  address?: string;
}

export const ContactModule: React.FC<ContactModuleProps> = ({
  className,
  title,
  text,
  email,
  address,
}) => {
  return (
    <Grid className={className || 'section'}>
      {title && (
        <Column sm={4} md={4} lg={4} offsetLeft={{ lg: 1 }}>
          <h3 className={cx('modulesTitleTypography', styles.title)}>{title}</h3>
        </Column>
      )}

      <Column sm={5} md={4} lg={4} offsetLeft={{ lg: 1 }}>
        {text && <p className={cx('paragraphTypography', styles.text)}>{text}</p>}{' '}
        {email && <Email label={email} />}
        {address && <Markdown className={styles.address}>{address}</Markdown>}
      </Column>
    </Grid>
  );
};
